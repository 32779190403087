body {
  margin: 0;
  padding: 0;
  background-color: #fbd38d;
  min-height: 100vh;
}

.react-datepicker-popper {
  z-index: 10000;
}

.langChange {
  cursor: pointer;
}

.langChange:hover {
  text-decoration: underline;
}
